import { Box, Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { StandardCard } from "../../../components/Cards";
import { FileUpload } from "../../../components/FileUpload/FileUpload";
import { PageLoader } from "../../../components/PageLoader/PageLoader";
import { PageContainer } from "../../../components/container/PageContainer";
import {
    CustomFormLabel,
    CustomTextField,
} from "../../../components/formsComponents";
import { useDispatchWrapper } from "../../../hooks";
import { defaultAiPdfUpload } from "../../../redux/file-upload-with-ai/file-upload-with-ai.state";
import { extractProductsFromExcelFileAsync, uploadProductDocumentAsync } from "../../../redux/file-upload-with-ai/file-upload-with-ai.actions";
import { IExcelTemplate } from "../../../redux/templates/excel-template/excel-template.types";
import { PreviewExcelProductsTable } from "../components/PreviewExcelProductsTable";
import { ProductsTemplateAutoSearch } from "../../../components/AutoCompleteSearches/ProductsTemplateAutoSearch";

export const getFileNameFromFilePath = (path: string | null | undefined) => {
    // path.match(/^.*?([^\\/.]*)[^\\/]*$/)[1]; // OR
    // path.replace(/^.*?([^\\\/]*)$/, '$1')    // OR
    // path.replace(/^.*[\\/]/, '')             // OR
    return path ? path.split(/(\\|\/)/g).pop()?.split(".")[0] : ""
}
export const getChipBackgroundColor = (accuracy: string) => {
    const acc = Number(accuracy);
    if (acc > 70) {
        return {
            backgroundColor: "#bbedbb",
            color: "#000000",
        };
    }
    return {
        backgroundColor: "#ff6666",
        color: "#ffffff",
    };
};


interface IImportExcelProductsProps {
    template: IExcelTemplate
}

export const ImportExcelProducts: React.FC<IImportExcelProductsProps> = ({
    template
}) => {

    const dispatch = useDispatchWrapper();

    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [screenType, setScreenType] = useState<"FILE_TYPE" | "UPLOAD_EXCEL" | "UPLOAD_PDF">("UPLOAD_PDF");
    const [documentUUID, setDocumentUUID] = useState<string | null>(null);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        isValid,
        resetForm,
    } = useFormik({
        initialValues: {
            document_name: getFileNameFromFilePath(template.base_file_path) || "",
            template_uuid: "",
            template_name: ""
        },
        validate: (values) => {
            const errors: any = {};

            return errors;
        },
        onSubmit: async (values) => {
            dispatch(
                uploadProductDocumentAsync(
                    {
                        ...defaultAiPdfUpload,
                        file_type: "EXCEL",
                        file_path: template.base_file_path || "",
                        name: values.document_name
                    },
                    (isSuccess, data) => {
                        if (isSuccess && data) {
                            dispatch(extractProductsFromExcelFileAsync(
                                {
                                    product_documents_uuid: data.product_documents_uuid || "",
                                    template_uuid: values.template_uuid || "",
                                    column_mapping: template.column_mapping,
                                    heading_row_end: template.heading_row.end,
                                    heading_row_start: template.heading_row.start,
                                }, (isSuccess, excelOptions) => {
                                if (isSuccess && excelOptions) {
                                    setDocumentUUID(data.product_documents_uuid)
                                }
                            }))

                        }
                    },
                ),
            );
        },
    });


    useEffect(() => {
        if (!template.template_uuid) return;
        setValues({
            document_name: getFileNameFromFilePath(template.base_file_path) || "",
            template_uuid: template.template_uuid,
            template_name: template.template_name || ""
        })
    }, [template])



    return (
        <PageLoader
            loading={false}
            error={null}
        >
            <PageContainer
                title={"Drawing"}
                description="this is inner page"
            >
                <StandardCard heading={`Document Information`}>
                    <Box component={"form"} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={4}>
                                <CustomFormLabel>Document Name</CustomFormLabel>
                                <CustomTextField
                                    id='document_name'
                                    name="document_name"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={values.document_name}
                                    onChange={handleChange}
                                    error={errors.document_name ? true : false}
                                    helperText={errors.document_name}
                                />
                            </Grid>
                           {values.template_uuid && <Grid item xs={4} md={4}>
                                <ProductsTemplateAutoSearch
                                    label='Choose Template'
                                    value={values.template_uuid || ""}
                                    onSelect={(templateData) => {
                                        setFieldValue("template_uuid", templateData.template_uuid)
                                    }}
                                />
                            </Grid>}

                            {/* <Grid item xs={12}>
                                <CustomFormLabel>File Upload</CustomFormLabel>
                                <FileUpload
                                    value={null}
                                    onChange={(file) => setFieldValue("file", file)}
                                    uploadType={"EXCEL"}
                                />
                            </Grid> */}
                        </Grid>
                        <Stack
                            mt={2}
                            direction={"row"}
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                        >
                            <Button
                                variant="contained"
                                type={"submit"}
                                disabled={!values.document_name}
                            >
                                Extract Products
                            </Button>
                        </Stack>
                    </Box>
                </StandardCard>


                {documentUUID && <PreviewExcelProductsTable documentUUID={documentUUID} onSaveSuccess={() => {}} />}


            </PageContainer>
        </PageLoader>
    );
};
