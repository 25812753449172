import React, { useEffect, useMemo, useState } from 'react'
import { api } from '../../../api/api';
import { useDispatchWrapper } from '../../../hooks';
import { closeLoaderWithMessage, openLoaderWithMessage } from '../../../redux/messages/messagesActions';

export type IFileExtension = "IMAGE" | "PDF" | "EXCEL" | "OTHER";
export const getFileExtensionType = (fileName: string): IFileExtension => {
    const extension = fileName.split(".").pop();
    if (
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png" ||
        extension === "webp" ||
        extension === "jfif" ||
        extension === "tif"
    )
        return "IMAGE";
    if (extension === "pdf") return "PDF";
    if (extension === "xls" || extension === "xlsx") return "EXCEL";
    return "OTHER";
};
export const getBase64Extension = (extension: string): string => {
    const fileType = getFileExtensionType(extension);
    if (fileType === "IMAGE") return `data:image/${extension};base64`;
    if (fileType === "EXCEL")
        return `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64`;
    return `data:application/${extension};base64`;
};

const arrayBufferToBase64V2 = (buffer: any, extension: string): string => {
    return `${getBase64Extension(extension)},${btoa(
        new Uint8Array(buffer).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
        }, ""),
    )}`;
};

export const downloadSingleFileAsync = async (fileUrl: string) =>
    new Promise<{ fileName: string; data: string; type: IFileExtension }>(
        (resolve, reject) => {
            const payload = {
                type: "json",
                keys: [fileUrl],
            };
            api.post("/general/download-files", payload)
                .then((res) => {
                    const fileName = fileUrl.split("/")[fileUrl.split("/").length - 1];
                    const fileExtension = fileName.split(".")[1];
                    const base64Data = arrayBufferToBase64V2(
                        res.data[0]?.data,
                        fileExtension,
                    );
                    resolve({
                        fileName: fileName,
                        data: base64Data,
                        type: getFileExtensionType(fileUrl),
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        },
    );



interface IPreviewPdfFileProps {
    filePath: string
}

export const PreviewPdfFile: React.FC<IPreviewPdfFileProps> = ({
    filePath
}) => {
    const dispatch = useDispatchWrapper()

    const [fileToView, setFileToView] = useState<string | null>(null)
    useEffect(() => {
        if(!filePath) return 
        dispatch(openLoaderWithMessage("Loading Please wait...."));
        downloadSingleFileAsync(filePath)
            .then((result) => {
                setFileToView(result.data)
            })
            .finally(() => {
                dispatch(closeLoaderWithMessage());
            });
    }, [filePath])


    return (
        <>

            {fileToView && (
                <iframe title='pdf-file-view'
                    src={`${fileToView}`}
                    style={{ width: "100%", height: "auto", minHeight: "90vh" }}
                />)}
        </>
    )
}
