import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { MODULE_IDS } from "../constants/enums";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { BeforeProfile } from "../views/Profile/UsersList/UsersList";
import { ForgetPassword } from "../views/authentication/ForgetPassword/ForgetPassword";
import { OOPSError } from "../views/authentication/OopsError";
import { ManageUserBranch } from "../views/dataManagement/userBranch/ManageUserBranch";
import { BranchList } from "../views/dataManagement/userBranch/UserBranchList";
import { ZoneList } from "../views/dataManagement/userZone/ZoneList";

import { productsRoutes } from "../views/products/Products.router";
import { supplier_module_routes } from "../views/supplier";
import { templatesRoutes } from "../views/template/Templates.router";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";
import { fileUploadWithAIRouter } from "../views/FileUploadWithAI/ImportLineSheet.router";
import { ordersRoutes } from "../views/Orders/Orders.router";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() =>
    import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
      default: FullLayout,
    })),
  ),
);
const BlankLayout = Loadable(
  lazy(() => import("../layout/blankLayout/BlankLayout")),
);
/* ***End Layouts**** */

const Error = Loadable(
  lazy(() =>
    import("../views/authentication/Error").then(({ Error }) => ({
      default: Error,
    })),
  ),
);

/* ****Pages***** */

const Dashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
      default: Dashboard,
    })),
  ),
);

const Login = Loadable(
  lazy(() =>
    import("../views/authentication/Login").then(({ Login }) => ({
      default: Login,
    })),
  ),
);
const AccessDenied = Loadable(
  lazy(() =>
    import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
      default: AccessDenied,
    })),
  ),
);



const ManageUser = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/ManageUser").then(({ ManageUser }) => ({
      default: ManageUser,
    })),
  ),
);

const ViewProfile = Loadable(
  lazy(() =>
    import("../views/Profile/ViewProfile/ViewProfile").then(
      ({ ViewProfile }) => ({
        default: ViewProfile,
      }),
    ),
  ),
);

const SecurityDashboard = Loadable(
  lazy(() =>
    import("../views/Security/CreateSecurityGroup").then(
      ({ CreateSecurityGroup }) => ({ default: CreateSecurityGroup }),
    ),
  ),
);

const SecurityApprovalsList = Loadable(
  lazy(() =>
    import("../views/Security/approval/ApprovalList").then(
      ({ ApprovalList }) => ({ default: ApprovalList }),
    ),
  ),
);

const ManageSecuirtyApproval = Loadable(
  lazy(() =>
    import("../views/Security/approval/ManageApproval").then(
      ({ ManageApproval }) => ({ default: ManageApproval }),
    ),
  ),
);

const ListSecurityGroups = Loadable(
  lazy(() =>
    import("../views/Security/ListSecurityGroups").then(
      ({ ListSecurityGroups }) => ({ default: ListSecurityGroups }),
    ),
  ),
);

const RoleGroupTable = Loadable(
  lazy(() =>
    import("../views/Security/roleGroup/RoleGroupTable").then(
      ({ RoleGroupTable }) => ({ default: RoleGroupTable }),
    ),
  ),
);




const QuotesInvoice = Loadable(
  lazy(() =>
    import("../publicModules/QuotesInvoice/QuotesInvoice").then(
      ({ QuotesInvoice }) => ({
        default: QuotesInvoice,
      }),
    ),
  ),
);



/************************ Approval ************************/

const ApprovalQuoteList = Loadable(
  lazy(() =>
    import("../views/approval/quote/ApprovalQuoteList").then(
      ({ ApprovalQuotesList }) => ({
        default: ApprovalQuotesList,
      }),
    ),
  ),
);

const ItemApprovalList = Loadable(
  lazy(() =>
    import("../views/approval/itemApprovals/ItemApprovalList").then(
      ({ ItemApprovalList }) => ({
        default: ItemApprovalList,
      }),
    ),
  ),
);
const ApprovalOrderRequest = Loadable(
  lazy(() =>
    import("../views/approval/approvalOrderRequest/ApprovalOrderRequest").then(
      ({ ApprovalOrderRequest }) => ({
        default: ApprovalOrderRequest,
      }),
    ),
  ),
);

// *********************** Ai Pdf Routes ************************/


export const Router = [
  {
    path: "/",

    element: (
      <ProtectedRoute>
        <FullLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <Dashboard />
          </AuthorizedRoute>
        ),
      },
      // **************** ai pdf upload routes **********************

      {
        path: "/view-profile",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
            <ViewProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
            <BeforeProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
            <BranchList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
            <ManageUserBranch />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch/manage/:branchUUId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
            <ManageUserBranch />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/zone-list",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
            <ZoneList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage/:userId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
            <ManageUser isMyProfile={false} />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/my-profile",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]} >
            <ManageUser isMyProfile={true} />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/list-security-groups",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.SECURITY]}>
            <ListSecurityGroups />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-security-group",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-security-group/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/secuirty-role-duplicate/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard isDuplicate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/approvals-list",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityApprovalsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/manage-approval",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.SECURITY]}>
            <ManageSecuirtyApproval />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/manage-approval/:approvalId",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.SECURITY]}>
            <ManageSecuirtyApproval />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/role-groups",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.SECURITY]}>
            <RoleGroupTable />
          </AuthorizedRoute>
        ),
      },
      /************************Approval ************************/
      {
        path: "/approval/quote",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.APPROVAL_QUOTE]}>
            <ApprovalQuoteList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/item",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.APPROVAL_ITEM]}>
            <ItemApprovalList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/order-request",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.ORDER_REQUESTED]}>
            <ApprovalOrderRequest />
          </AuthorizedRoute>
        ),
      },

      { path: "access", element: <AccessDenied /> },
      { path: "error", element: <OOPSError /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/", element: <Navigate to="/dashboard" /> },
    ].concat(productsRoutes)
      .concat(templatesRoutes)
      .concat(supplier_module_routes)
      .concat(fileUploadWithAIRouter)
      .concat(ordersRoutes)
  },

  // --------------------------------TASK ROUTING----------------------------------------------

  {
    path: "/auth",
    element: (
      <PublicRoute>
        <BlankLayout />
      </PublicRoute>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "forget-password", element: <ForgetPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/public/quotes/:quoteId",
    element: <QuotesInvoice />,
  },
];
