import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { api } from "../../api/api";
import { uploadFile } from "../../helpers/uploadFile";
import { IStoreState } from "../initialStoreState";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { IFileUploadWithAI } from "./file-upload-with-ai.types";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";



export const uploadProductDocumentAsync = (
    data: IFileUploadWithAI,
    onCallback: (isSuccess: boolean, data?: IFileUploadWithAI) => void,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
        try {
            dispatch(saveLoaderProgress());
            const {
                create_ts,
                insert_ts,
                ...rest
            } = data;
            const payload = {
                ...rest,
                file_type: rest.file_type === "EXCEL" ? "XLSX" : rest.file_type
            };
            const res = await api.post("/product/upsert-product-documents", payload);
            dispatch(
                showMessage({
                    type: "success",
                    message: "Actions save successfully!",
                    displayAs: "snackbar",
                }),
            );
            onCallback(true, res.data.data);
        } catch (err: any) {
            onCallback(false);
            dispatch(
                showMessage({
                    type: "error",
                    message: err.response.data.message,
                    displayAs: "snackbar",
                }),
            );
        } finally {
            dispatch(saveLoaderCompleted());
        }
    };

export const extractProductWithAiAsync = (
    product_documents_uuid: string,
    onCallback: (isSuccess: boolean, data?: IFileUploadWithAI) => void,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
        try {
            dispatch(saveLoaderProgress());

            const res = await api.post("/genai/extract-product-with-gen-ai", {
                product_documents_uuid
            });
            dispatch(
                showMessage({
                    type: "success",
                    message: "Actions save successfully!",
                    displayAs: "snackbar",
                }),
            );
            onCallback(true, res.data.data);
        } catch (err: any) {
            onCallback(false);
            dispatch(
                showMessage({
                    type: "error",
                    message: err.response.data.message,
                    displayAs: "snackbar",
                }),
            );
        } finally {
            dispatch(saveLoaderCompleted());
        }
    };






export const extractProductsFromExcelFileAsync = (
    payload: {
        product_documents_uuid: string,
        template_uuid: string,
        column_mapping?: any,
        sheet_count?: number,
        heading_row_start?: number,
        heading_row_end?: number
    },
    onCallback: (isSuccess: boolean, data?: IFileUploadWithAI) => void,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const { product_documents_uuid, template_uuid } = payload

            dispatch(saveLoaderProgress());
            const res = await api.post("/product/extract-product-from-excel", template_uuid ? {
                product_documents_uuid,
                template_uuid
            } : payload);
            dispatch(
                showMessage({
                    type: "success",
                    message: "Products Fetched successfully!",
                    displayAs: "snackbar",
                }),
            );
            onCallback(true, res.data.data);
        } catch (err: any) {
            onCallback(false);
            dispatch(
                showMessage({
                    type: "error",
                    message: err.response.data.message,
                    displayAs: "snackbar",
                }),
            );
        } finally {
            dispatch(saveLoaderCompleted());
        }
    };


export const fetchTemporaryProductsAsync = (
    queryParams: IQueryParams,
    product_documents_uuid: string,
    onCallback: (isSuccess: boolean, data?: any) => void,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const searchQuery = getSearchQuery(queryParams);
            dispatch(saveLoaderProgress());
            const res = await api.get(`/product/get-temp-product${searchQuery}&product_documents_uuid=${product_documents_uuid}`);
            dispatch(
                showMessage({
                    type: "success",
                    message: "Products Fetched successfully!",
                    displayAs: "snackbar",
                }),
            );
            onCallback(true, res.data);
        } catch (err: any) {
            onCallback(false);
            dispatch(
                showMessage({
                    type: "error",
                    message: err.response.data.message,
                    displayAs: "snackbar",
                }),
            );
        } finally {
            dispatch(saveLoaderCompleted());
        }
    };



export const moveProductsFromTemporaryTableAsync = (
    product_documents_uuid: string,
    onCallback: (isSuccess: boolean, data?: IFileUploadWithAI) => void,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
        try {
            dispatch(saveLoaderProgress());
            const res = await api.post("/product/move-product-from-temp", {
                product_documents_uuid,
            });
            dispatch(
                showMessage({
                    type: "success",
                    message: "Products Saved successfully!",
                    displayAs: "snackbar",
                }),
            );
            onCallback(true, res.data.data);
        } catch (err: any) {
            onCallback(false);
            dispatch(
                showMessage({
                    type: "error",
                    message: err.response.data.message,
                    displayAs: "snackbar",
                }),
            );
        } finally {
            dispatch(saveLoaderCompleted());
        }
    };
