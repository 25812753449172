/* eslint-disable react-hooks/exhaustive-deps */

import Avatar from "@mui/material/Avatar";
import React, { useState } from "react";
import { StandardCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
    IDataTableProps,
    MobileLogoRenderType,
    RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
    CSVExportPlugin,
    RefreshPlugin,
    SearchPlugin,
} from "../../../components/Table/plugins";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper } from "../../../hooks";
import { CustomSelect } from "../../../components/formsComponents";
import { Box, Button, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { clearProduct, fetchProductsListAsync } from "../../../redux/products/product.actions";
import { fetchTemporaryProductsAsync, moveProductsFromTemporaryTableAsync } from "../../../redux/file-upload-with-ai/file-upload-with-ai.actions";

interface IPreviewExcelProductsTableProps {
    documentUUID: string
    onSaveSuccess: () => void
}

export const PreviewExcelProductsTable: React.FC<IPreviewExcelProductsTableProps> = ({
    documentUUID,
    onSaveSuccess
}) => {

    const [productsList, setProductsList] = useState<{ data: any, totalRecords: number }>({ data: [], totalRecords: 0 })
    const [tablePagination, setTablePagination] = React.useState({
        pageNumber: 1,
        rowsInPerPage: 10,
    });

    const [searchType, setSearchType] = React.useState<string[]>([]);
    const [search, setSearch] = React.useState<string>("");
    const theme = useTheme();

    const [status, setStatus] = React.useState<string>("-1");

    const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
        useDateFilter({ defaultType: "last28Days" });

    const dispatch = useDispatchWrapper();
    const navigate = useNavigate();

    const productsTableProps: IDataTableProps = {
        isPagination: true,
        totalRecords: productsList?.totalRecords,
        rowsPerPageOptions: tablePagination.rowsInPerPage,
        isDataLoading: false,// loading !== LoadState.Loaded,
        selectionMode: "none",
        uniqueRowKeyName: "id",
        tableCommandBarProps: {
            leftItems: {

            },
            rightItems: {

            },
        },
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },
        columns: [
            {
                key: "preview_url",
                headerName: "Products",
                fieldName: "preview_url",
                enableSorting: true,
                // isFirstColumnSticky: true,
                renderType: RenderType.CUSTOM_RENDER,
                exportCellWidth: 15,
                onRowCellRender: (value, row: any) => {
                    return <Stack direction={'row'} alignItems={'center'} spacing={2} mb={2}  >
                        <Stack height={100} width={130} borderRadius={'10px'}>
                            {row.preview_url?.length > 0 ? <img src={row.preview_url} alt="product" height={'100%'} width={'100%'} style={{ borderRadius: '10px', objectFit: "cover", objectPosition: "top" }} />
                                :
                                <Box sx={{ background: "#c2c2c2", height: 100, width: 100, borderRadius: "50%" }} />
                            }
                        </Stack>
                        <Stack>
                            <Typography variant="h5" fontSize={'1.2rem'} fontWeight={500}>{row.name}</Typography>
                            <Typography variant="body1" fontSize={'1.1rem'} sx={{
                                color: theme.palette.grey[600],
                            }} fontWeight={500}>{row.category}</Typography>
                        </Stack>
                    </Stack>;
                },
            },
            {
                key: "category",
                headerName: "Category",
                fieldName: "category",
                renderType: RenderType.TEXT_DARK,
                exportCellWidth: 30,
                enableSorting: true,
            },
            {
                key: "review",
                headerName: "Reveiw",
                fieldName: "review",
                renderType: RenderType.CHIP_SUCCESS,
                exportCellWidth: 30,
                enableSorting: true,
            },
            {
                key: "tax",
                headerName: "Tax",
                fieldName: "tax",
                renderType: RenderType.TEXT,
                exportCellWidth: 30,
                enableSorting: true,
            },
        ],
        items: productsList?.data || [],
        onPageChange: (newPageNumber: number) => {
            setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setTablePagination({
                pageNumber: pageNumber,
                rowsInPerPage: rowsPerPage,
            });
        },
    };

    const fetchList = () => {
        dispatch(
            fetchTemporaryProductsAsync({
                page: tablePagination.pageNumber,
                rowsPerPage: tablePagination.rowsInPerPage,
                status,
                fromDate: date.fromDate,
                toDate: date.toDate,
                value: search,
                columns: searchType
            }, documentUUID, (isSuccess, data) => {
                if (isSuccess && data) {
                    setProductsList(data)
                }
            })
        );
    };

    React.useEffect(() => {
        if (!documentUUID) return;
        fetchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentUUID, tablePagination, date, status, search, searchType]);


    const handleFinalSubmission = () => {
        dispatch(moveProductsFromTemporaryTableAsync(documentUUID, (isSuccess, data) => {
            if (isSuccess) {
                navigate(`/items/products`)
            }
        }))
    }

    return (
        <PageContainer title="Line Sheet Items" description="this is inner page">
            <StandardCard
                sx={{ mt: 1 }}
                heading="Line Sheet Items"
            >
                <DataTable {...productsTableProps} />

            </StandardCard>


            <Stack mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"}>
                <Button variant="contained" sx={{ maxWidth: 150 }} onClick={handleFinalSubmission}>Save</Button>
            </Stack>
        </PageContainer>
    );
};
