import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { closeLoaderWithMessage, openLoaderWithMessage, saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { uploadFile } from "../../../components/FileUpload/utils";
import { fetchTemplateSuccess } from "../templateActions";
import { makeApiCall } from "../../../helpers/makeApiCall";
import { IExcelTemplate, IUpsertExcelTemplateStepTwo } from "./excel-template.types";
import { ITemplate } from "../templates.types";
import { action } from "typesafe-actions";
import { api } from "../../../api/api";




export const FETCH_EXCEL_TEMPLATE_PROGRESS = "FETCH_EXCEL_TEMPLATE_PROGRESS";
export const FETCH_EXCEL_TEMPLATE_SUCCESS = "FETCH_EXCEL_TEMPLATE_SUCCESS";
export const FETCH_EXCEL_TEMPLATE_FAILED = "FETCH_EXCEL_TEMPLATE_FAILED";

export const fetchExcelTemplateProgress = () => action(FETCH_EXCEL_TEMPLATE_PROGRESS);
export const fetchExcelTemplateSuccess = (data: IExcelTemplate) =>
    action(FETCH_EXCEL_TEMPLATE_SUCCESS, { data });
export const fetchExcelTemplateFailed = (errorMessage: string) =>
    action(FETCH_EXCEL_TEMPLATE_FAILED, { errorMessage });

export const fetchSingleExcelTemplateAsync =
    (template_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchExcelTemplateProgress());
                const res = await api.get(
                    `/template/get-template?template_uuid=${template_uuid}&pageNo=1&itemPerPage=10`
                );
                const data = res.data.data;
                if (data.length > 0) {
                    dispatch(fetchExcelTemplateSuccess(data[0]));
                } else {
                    dispatch(
                        fetchExcelTemplateFailed(
                            "Unfortunately, there are no records available at the moment."
                        )
                    );
                }
            } catch (err: any) {
                dispatch(fetchExcelTemplateFailed("Something went to be wrong!"));
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    })
                );
            }
        };

export const getExcelFileHeaderAsync =
    (
        excel_payload: IUpsertExcelTemplateStepTwo,
        onCallback: (isSuccess: boolean, data?: any) => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(openLoaderWithMessage("File is being processed..."));
                const res = await makeApiCall(
                    {
                        url: "/template/get-excel-header",
                        method: "POST",
                    },
                    undefined,
                    {
                        ...excel_payload,
                    url: `${process.env["REACT_APP_SERVER_URL"]}/general/get-signed-url?key=${excel_payload.url}`
                    }
                );

                dispatch(
                    showMessage({
                        type: "success",
                        message: "File Processed successfully",
                        displayAs: "snackbar",
                    })
                );
                // dispatch(fetchTemplateSuccess(finalDocument));
                onCallback(true, res.data.data);
            } catch (err: any) {
                onCallback(false);
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    })
                );
            } finally {
                dispatch(closeLoaderWithMessage());
            }
        };


export const getExcelColumnMappingWithAIAsync =
    (
        data: any,
        onCallback: (isSuccess: boolean, data?: any) => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(openLoaderWithMessage("Mappings are being Generated..."));

                const res = await makeApiCall(
                    {
                        url: "/genai/excel-mapping-with-gen-ai",
                        method: "POST",
                    },
                    undefined,
                    {
                        "excel_headers": data
                    }
                );

                const finalDocument: IExcelTemplate = res.data.data;

                dispatch(
                    showMessage({
                        type: "success",
                        message: "Mappings Generated successfully",
                        displayAs: "snackbar",
                    })
                );
                onCallback(true, finalDocument);
            } catch (err: any) {
                onCallback(false);
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    })
                );
            } finally {
                dispatch(closeLoaderWithMessage());
            }
        };


export const upsertExcelTemplateAsync =
    (
        data: IExcelTemplate,
        // file: File | null,
        onCallback: (isSuccess: boolean, template_info?: IExcelTemplate) => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                const {
                    create_ts,
                    insert_ts,
                    ...rest
                } = data;
                dispatch(saveLoaderProgress());
                // const asPayload = {
                //     template_unique_no: rest.template_name,
                // };
                // const path = await uploadFile(
                //     file,
                //     "TEMPLATE",
                //     data.base_file_path as string,
                //     asPayload
                // );
                const res = await makeApiCall(
                    {
                        url: "/template/upsert-template",
                        method: "POST",
                    },
                    undefined,
                    {
                        ...rest,
                        // base_file_path: path,
                    }
                );

                const finalDocument: IExcelTemplate = res.data.data;

                dispatch(
                    showMessage({
                        type: "success",
                        message: "Template saved successfully",
                        displayAs: "snackbar",
                    })
                );
                dispatch(fetchTemplateSuccess(finalDocument));
                onCallback(true, finalDocument);
            } catch (err: any) {
                onCallback(false);
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    })
                );
            } finally {
                dispatch(saveLoaderCompleted());
            }
        };



export const CLEAR_EXCEL_TEMPLATES = "CLEAR_EXCEL_TEMPLATES";
export const clearExcelTemplateState = () => action(CLEAR_EXCEL_TEMPLATES);