import { combineReducers } from "redux";

import { IStoreState } from "./initialStoreState";
import { messageReducer } from "./messages/messagesReducer";

import { commonReducer } from "./common/commonReducer";

import { UserProfileReducer } from "./UserProfileList/userProfileReducer";

import { customizerReducer } from "./customizer/customizerReducer";
import { companyReducer } from "./company/companyReducer";
import { productsReducer } from "./products/product.reducer";
import { quotesReducer } from "./quote/quoteReducer";
import { securityReducer } from "./security/securityReducer";
import { dataManagementReducer } from "./DataManagement/dataManagementReducer";
import { quotesApprovalReducer } from "./approval/quote";
import { commentReducer } from "./comments/comment.reducer";
import { aprovalsReducer } from "./approval/approvals/approvalsReducer";
import { ecnReducer } from "./ecn";
import { approvalItemReducer } from "./approval/productExpiry";
import { ncpReducer } from "./ncp";
import { workOrderReducer } from "./workOrder";
import { templateReducer } from "./templates";
import { supplierReducer } from "./supplier/supplier.reducer";
import { ordersReducer } from "./orders";

const appReducer = combineReducers({
  userprofile: UserProfileReducer,
  customizerReducer: customizerReducer,
  company: companyReducer,
  products: productsReducer,
  ecn: ecnReducer,
  ncp: ncpReducer,
  message: messageReducer,
  common: commonReducer,
  quotes: quotesReducer,
  security: securityReducer,
  dataManagement: dataManagementReducer,
  approvalQuotes: quotesApprovalReducer,
  approvalItem: approvalItemReducer,
  comment: commentReducer,
  approvals: aprovalsReducer,
  workOrder: workOrderReducer,
  templates: templateReducer,
  supplier: supplierReducer,
  orders: ordersReducer
});

export const rootReducer = (state: IStoreState | undefined, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
