import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import {
    saveLoaderCompleted,
    saveLoaderProgress,
    showMessage,
} from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IOrder, IOrdersState } from "./orders.types";

export const FETCH_ORDER_LIST_PROGRESS = "FETCH_ORDER_LIST_PROGRESS";
export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS";
export const FETCH_ORDER_LIST_FAILED = "FETCH_ORDER_LIST_FAILED";

export const fetchOrdersListProgress = () =>
    action(FETCH_ORDER_LIST_PROGRESS);

export const fetchOrdersListSuccess = (
    data: IOrdersState["orders_list"]["data"],
    totalRecords: number,
) => action(FETCH_ORDER_LIST_SUCCESS, { data, totalRecords });
export const fetchOrdersListFailed = () => action(FETCH_ORDER_LIST_FAILED);

export const fetchOrdersListAsync =
    (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                const searchQuery = getSearchQuery(queryParams);
                dispatch(fetchOrdersListProgress());
                const res = await api.get(`/order/get-orders${searchQuery}`);
                const data: IOrdersState["orders_list"]["data"] = res.data.data;

                const totalRecords = res.data.totalRecords;

                dispatch(fetchOrdersListSuccess(data, totalRecords));
            } catch (err: any) {
                dispatch(fetchOrdersListFailed());
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    }),
                );
            }
        };





export const FETCH_SINGLE_ORDER_PROGRESS = "FETCH_SINGLE_ORDER_PROGRESS";
export const FETCH_SINGLE_ORDER_SUCCESS = "FETCH_SINGLE_ORDER_SUCCESS";
export const FETCH_SINGLE_ORDER_FAILED = "FETCH_SINGLE_ORDER_FAILED";

export const fetchSingleOrderProgress = () => action(FETCH_SINGLE_ORDER_PROGRESS);
export const fetchSingleOrderSuccess = (data: IOrder) =>
    action(FETCH_SINGLE_ORDER_SUCCESS, { data });
export const fetchSingleOrderFailed = (errorMessage: string) =>
    action(FETCH_SINGLE_ORDER_FAILED, { errorMessage });

export const fetchSingleOrderAsync =
    (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchSingleOrderProgress());
                const res = await api.get(`/order/get-orders?orders_uuid=${uuid}&pageNo=1&itemPerPage=11`);
                const data: IOrder[] = res.data.data;
                if (data.length > 0) {
                    dispatch(fetchSingleOrderSuccess(data[0]));
                } else {
                    dispatch(fetchSingleOrderFailed("Oops! We couldn't find any records."));
                }
            } catch (err: any) {
                dispatch(fetchSingleOrderFailed("Something went to be wrong!"));
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    }),
                );
            }
        };

export const upsertSingleOrderAsync =
    (
        data: IOrder,
        onCallback: (isSuccess: boolean, order?: IOrder) => void,
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                const {
                    create_ts,
                    insert_ts,
                    ...payload
                } = data;
                dispatch(saveLoaderProgress());
                const res = await api.post("/order/upsert-orders", payload);
                let message = "Order saved successfully!";
                onCallback(true, res.data.data);
                dispatch(
                    showMessage({
                        type: "success",
                        displayAs: "snackbar",
                        message: message,
                    }),
                );
            } catch (err: any) {
                onCallback(false);
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    }),
                );
            } finally {
                dispatch(saveLoaderCompleted());
            }
        };

export const CLEAR_SINGLE_ORDER = "CLEAR_SINGLE_ORDER";
export const CLEAR_ORDERS_STATE = "CLEAR_ORDERS_STATE";
export const clearSingleOrderSync = () => action(CLEAR_SINGLE_ORDER);
export const clearOrdersStateSync = () => action(CLEAR_ORDERS_STATE);
