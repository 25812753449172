import { LoadState } from "../../constants/enums";
import { IOrder, IOrderItem, IOrdersState } from "./orders.types";


export const defaultOrderItem: IOrderItem = {
    order_item_uuid: null,
    orders_uuid: null,
    product_uuid: null,
    product_name: "",
    variant: {},
    quantity: 0,
    price: 0,
    total_price: 0,
    status: "ACTIVE",
}
export const defaultOrder: IOrder = {
    orders_uuid: "",
    supplier_uuid: "",
    supplier_name: "",
    supplier_email: "",
    total_amount: null,
    order_item_list: [defaultOrderItem],
    shipping_address_line_1: null,
    shipping_address_line_2: null,
    shipping_city: null,
    shipping_state: null,
    shipping_pincode: null,
    shipping_country: null,
    billing_address_line_1: null,
    billing_address_line_2: null,
    billing_city: null,
    billing_state: null,
    billing_pincode: null,
    billing_country: null,
    status: "DRAFT"
}

export const defaultOrdersState: IOrdersState = {
    orders_list: {
        loading: LoadState.NotLoaded,
        data: [],
        total_count: 0,
        error: null
    },
    single_order: {
        loading: LoadState.NotLoaded,
        data: defaultOrder,
        error: null
    }
}