import { LoadState } from "../../../constants/enums";
import { ISupplierDeliveryAddress, ISupplierDeliveryAddressState } from "./supplier-delivery-address.types";

export const defaultSupplierDeliveryAddress: ISupplierDeliveryAddress = {
    supplier_delivery_address_uuid: "",
    supplier_uuid: null,
    supplier_name: "",
    delivery_name: null,
    supplier_delivery_address_line1: null,
    supplier_delivery_address_line2: null,
    supplier_delivery_address_city: null,
    supplier_delivery_address_state: null,
    supplier_delivery_address_pincode: null,
    supplier_delivery_address_country: null,
    status: "ACTIVE"
};


export const defaultSupplierDeliveryAddressState: ISupplierDeliveryAddressState = {
    supplier_delivery_address_list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null,
    },
    single_supplier_delivery_address: {
        data: defaultSupplierDeliveryAddress,
        loading: LoadState.NotLoaded,
        error: null,
    },
};
