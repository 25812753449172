import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IProduct } from "./products.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { uploadFile } from "../../helpers/uploadFile";

export const FETCH_PRODUCTS_LIST_PROGRESS = "FETCH_PRODUCTS_LIST_PROGRESS";
export const FETCH_PRODUCTS_LIST_SUCCESS = "FETCH_PRODUCTS_LIST_SUCCESS";
export const FETCH_PRODUCTS_LIST_FAILED = "FETCH_PRODUCTS_LIST_FAILED";

export const fetchProductsListProgress = () =>
  action(FETCH_PRODUCTS_LIST_PROGRESS);
export const fetchProductsListSuccess = (
  data: IProduct[],
  totalRecords: number,
) => action(FETCH_PRODUCTS_LIST_SUCCESS, { data, totalRecords });
export const fetchProductsListFailed = () => action(FETCH_PRODUCTS_LIST_FAILED);

export const fetchProductsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchProductsListProgress());
        let url = "";
        if (status !== "-1") {
          url = "&status=" + status;
        }

        let finalUrl = `/product/get-product?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
        if (searchType.length > 0 && searchValue) {
          finalUrl = `/product/get-product?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&columns=${[
            searchType,
          ]}&value=${searchValue}`;
        }
        const res = await api.get(finalUrl);
        const data: IProduct[] = res.data.data;

        const totalRecords = res.data.totalRecords;

        dispatch(fetchProductsListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchProductsListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertProductsAsync =
  (
    data: IProduct,
    file: any | null,
    onCallback: (isSuccess: boolean, product?: IProduct) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        const {
          create_ts,
          insert_ts,
          expiry_date_remaining,
          ...rest
        } = data;
        const asPayload = {
          product_unique_no: data.product_name,
        };
        const path = await uploadFile(
          file,
          "PRODUCT",
          data.primary_image_url || "",
          asPayload as any,
        );
        const payload = {
          ...rest,
          primary_image_url: path,
        };
        const res = await api.post("/product/upsert-product", payload);
        dispatch(
          showMessage({
            type: "success",
            message: "Item save successfully!",
            displayAs: "snackbar",
          }),
        );
        onCallback(true, res.data.data);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const FETCH_PRODUCT_PROGRESS = "FETCH_PRODUCT_PROGRESS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILED = "FETCH_PRODUCT_FAILED";

export const fetchProductProgress = () => action(FETCH_PRODUCT_PROGRESS);
export const fetchProductSuccess = (data: IProduct) =>
  action(FETCH_PRODUCT_SUCCESS, { data });
export const fetchProductFailed = (errorMessage: string) =>
  action(FETCH_PRODUCT_FAILED, { errorMessage });

export const fetchProductAsync =
  (id: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchProductProgress());
        const res = await api.get(`/quotes/get-products?product_uuid=${id}`);
        const data: IProduct[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchProductSuccess(data[0]));
        } else {
          dispatch(fetchProductFailed("Oops! We couldn't find any records."));
        }
      } catch (err: any) {
        dispatch(fetchProductFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const extendProductExpiry =
  (
    productUUID: string,
    onCallback: (isSuccess: boolean, date?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const res = await api.put(`/quotes/extend-product-expiry`, {
          product_uuid: productUUID,
        });

        const data = res.data.data;
        if (data.length > 0) {
          onCallback(true, data);
        }
      } catch (err: any) {
        dispatch(fetchProductFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const CLEAR_PRODUCT_STATE = "CLEAR_PRODUCT_STATE";
export const clearProduct = () => action(CLEAR_PRODUCT);
export const clearProductState = () => action(CLEAR_PRODUCT_STATE);
