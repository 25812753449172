import { LoadState } from "../../constants/enums";
import { defaultSupplierAttachmentState } from "./supplier-attachments/supplier-attachments.state";
import { defaultSupplierBankDetailsState } from "./supplier-bank-details";
import { defaultSupplierBranchState } from "./supplier-branch/supplier-branch.state";
import { defaultSupplierContactState } from "./supplier-contact/supplier-contact.state";
import { defaultSupplierDeliveryAddressState } from "./supplier-delivery-address/supplier-delivery-address.state";
import { ISupplier, ISupplierState } from "./supplier.types";


export const defaultSupplier: ISupplier = {
    supplier_uuid: "",
    supplier_name: "",
    supplier_website: "",
    legal_entity: "",
    created_by_uuid: "",
    organization_type: "",
    status: "ACTIVE",
    create_ts: "",
    insert_ts: "",
  };


  export const defaultSupplierState: ISupplierState = {
    supplier_list: {
      data: [],
      totalRecords: 0,
      loading: LoadState.NotLoaded,
      error: null,
    },
    single_supplier: {
      data: defaultSupplier,
      loading: LoadState.NotLoaded,
      error: null,
    },
    ...defaultSupplierBranchState,
    ...defaultSupplierContactState,
    ...defaultSupplierBankDetailsState,
    ...defaultSupplierDeliveryAddressState,
    ...defaultSupplierAttachmentState
  };
  